<template>
  <!-- ADD USER && EDIT USER -->
  <div class="centerx">
    <vs-popup
      class="holamundo"
      :title="action == 'edit' ? 'تعديل عضو' : 'إضافة عضو'"
      :active.sync="popupAddUser"
      :button-close-hidden="true"
    >
      <div class="add_group">
        <div v-if="error_mesage.length" class="error_mesage">
          <h3>برجاء ادخال البيانات التاليه :</h3>
          <ul>
            <li v-for="err in error_mesage" :key="err">
              {{ err }}
            </li>
          </ul>
        </div>

        <div v-if="API_ERROR == 'email_taken'" class="error_mesage">
          <p>هذا البريد موجود بالفعل بالرجاء إختيار بريد إلكترونى اخر</p>
        </div>
        <div v-if="API_ERROR == 'mobile_taken'" class="error_mesage">
          <p>هذا الرقم موجود بالفعل بالرجاء إختيار رقم هاتف اخر</p>
        </div>
         <div v-if="API_ERROR == 'mobile_notvalid'" class="error_mesage">
         <p>رقم الهاتف غير صالح</p>
        </div>
        <div v-if="API_ERROR == 'email_notvalid'" class="error_mesage">
         <p> بريد إلكترونى غير صالح</p>
        </div>
        
        
        <div class="form_body">
          <div class="metting_info justify-content-center d-flex">
            <form class="row">
              <div class="col-md-12 form-group  m-0 text-center">
                <div class="photo text-center">
                  <div
                    class="imagePreviewWrapper"
                    :style="{ 'background-image': `url(${previewImage})` }"
                  >
                    <label
                      title="رفع صورة المستخدم"
                      class="image_uplode_input_label"
                    >
                      <i class="fas fa-upload"></i>
                      <input
                        class="image_uplode_input"
                        ref="fileInput"
                        type="file"
                        @input="pickFile"
                        size="60"
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group   col-md-6 d-block">
                <label for="name">اسم الشخص</label>
                <input
                  :class="[error_mesage.includes('إسم الشخص') && 'err_input']"
                  required
                  v-model="form.name"
                  type="text"
                  placeholder="اسم الشخص"
                  id="name"
                />
              </div>
              <div class="form-group   col-md-6 d-block">
                <label for="email">البريد الإلكتروني</label>
                <input
                  :class="[
                    error_mesage.includes('البريد الإلكترونى') && 'err_input',
                  ]"
                  required
                  v-model="form.email"
                  type="email"
                  placeholder="البريد الإلكترونى"
                  id="email"
                />
              </div>
              <div class="form-group   col-md-6 d-block">
                <label for="phonenumber">رقم الموبايل</label>
                <vue-tel-input
                :class="[
                    error_mesage.includes('رقم الموبايل') && 'err_input',
                  ]"
                  v-model="form.mobile"
                  id="phonenumber"
                  @country-changed="handlePhoneCountry"
                  inputOptions.placeholder="رقم الموبايل"
                ></vue-tel-input>
              </div>

              <div class="form-group   col-md-6 d-block">
                <label for="company">اسم الشركة</label>
                <input
                  v-model="form.company"
                  type="text"
                  placeholder="اسم الشركة"
                  id="company"
                />
              </div>
              <div class="form-group   col-md-6 d-block">
                <label for="job_title">المسمي الوظيفي</label>
                <input
                  v-model="form.job_title"
                  type="text"
                  placeholder="المسمي الوظيفي"
                  id="job_title"
                />
              </div>
              <div class="form-group   col-md-6 d-block">
                <label for="department">اسم الإدارة التابع لها</label>
                <input
                  v-model="form.department"
                  type="text"
                  placeholder="اسم الإدارة التابع لها"
                  id="department"
                />
              </div>
              <div class="form-group   col-md-12 d-block">
                
                <label for="role_id">نوع العضوية</label>
                <v-select
                :class="[
                    error_mesage.includes('نوع العضوية') && 'err_input',
                  ]"
                  :options="AllRoles"
                  v-model="form.role"
                  placeholder="نوع العضوية"
                  label="name"
                />
              </div>
            </form>
          </div>
          <div
            v-if="action == 'edit'"
            class="popoup_footer justify-content-end d-flex position_relative"
          >
            <div class="d-flex">
              <button class="btn_Gray btn_main" @click="closePopup()">
                <span>خروج</span>
              </button>
              <button
                class="btn_main btn_Green mr-2 ml-0"
                @click="EditUser(selectedUser.id)"
              >
                <span>تعديل البيانات</span>
              </button>
            </div>
          </div>

          <div
            v-else
            class="popoup_footer justify-content-between d-flex position_relative"
          >
            <div class="d-flex">
              <vs-checkbox
                v-model="keepScrean"
                color="#1bb04e"
                style="height: 45px"
              ></vs-checkbox>
              <h6 class="mt-5" style="font-size: 14px; color: #676767">
                إضافة شخص اخر مع الاختفاظ بالشاشة الحالية
              </h6>
            </div>
            <div class="list_actions">
              <button class="btn_Gray btn_main " @click="closePopup()">
                <span>خروج</span>
              </button>

              <button class="btn_main btn_Green mr-2 ml-0" @click="CreateUser()">
                <span>حفظ البيانات</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from "vue-select";
import users from "../../http/requests/users";
export default {
  name: "AddMembers",
  components: { "v-select": vSelect },
  props: ["popupAddUser", "closePopup", "getAllUser", "action", "selectedUser"],
  data() {
    return {
      search_word: undefined,
      totalPages: 0,
      page: 1,
      keepScrean: false,
      previewImage: null,
      user_photo: "",
      form: {},
      error_mesage: [],
      AllRoles: [],
      API_ERROR:'',
      // MainColor: '#1bb04e'
    };
  },
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      this.user_photo = input.files[0];
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },
    handlePhonenumber(mobile) {
      this.form.mobile = mobile.replace(/\s/g, "");;
    },
    handlePhoneCountry(info) {
      this.form.country_code = info.dialCode;
    },

    getSingleUser(id) {
      this.form = {};
      this.$vs.loading();
      users
        .getSingleusers(id)
        .then((res) => {
          this.form = res.data.data;
          this.previewImage =res.data.data.avatar
          this.$vs.loading.close();
        })
          .catch((err) => {
            this.$vs.loading.close();
            if(err.response.data.error){
              this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
            }
            else{
              
               this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
            }
           
          });
        
    },
    CreateUser() {
      this.API_ERROR =''
      this.error_mesage=[]
      if (!this.form.name) {
        this.error_mesage.push("إسم الشخص");
      }
      if (!this.form.email) {
        this.error_mesage.push("البريد الإلكترونى");
      }
      if (!this.form.mobile) {
        this.error_mesage.push("رقم الموبايل");
      }
      if(!this.form.role){
         this.error_mesage.push("نوع العضوية");
      }
   window.scrollTo(0, 0);
      if (this.form.name && this.form.email && this.form.mobile && this.form.role) {
        let formData = new FormData();
        formData.append("name", this.form.name);
        formData.append("email", this.form.email);
        formData.append("company", this.form.company);
        formData.append("job_title", this.form.job_title);
        formData.append("department", this.form.department);
        formData.append("country_code", this.form.country_code);
        formData.append("mobile", this.form.mobile.replace(/\s/g, ""));
        formData.append("avatar", this.user_photo);
          formData.append("role_id", this.form.role.id);
   

        this.$vs.loading();
        users
          .CreateUsers(formData)
          .then(() => {
            this.form = {};
            this.getAllUser();
            this.$vs.loading.close();
            this.$vs.notify({
              text: "تم إضافة العضو بنجاح",
              color: "success",
            });

            if (!this.keepScrean) {
              this.closePopup();
            }
          })
          // 
          .catch((err) => {
            this.$vs.loading.close();
           
            if(err.response.data.error=='The email has already been taken.'){
              this.API_ERROR = 'email_taken'
            }
            else if(err.response.data.error=='The mobile has already been taken.'){
              this.API_ERROR = 'mobile_taken'
            
              
            }
            else if(err.response.data.error=='The mobile must be a number.'){
              this.API_ERROR = 'mobile_notvalid'
            }
            else if(err.response.data.error=='The email must be a valid email address.'){
               this.API_ERROR = 'email_notvalid'
            }
            else{
              if(err.response.data.error){
              this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
            }
            else{
                if(err.response.data.error){
              this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
            }
            else{
              
               this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
            }
            }
            }
            
            window.scrollTo(0,0)
          });
      }
    },
    EditUser(userid) {
     this.error_mesage=[]
     this.API_ERROR =''
      if (!this.form.name) {
        this.error_mesage.push("إسم الشخص");
      }
      if (!this.form.email) {
        this.error_mesage.push("البريد الإلكترونى");
      }
      if (!this.form.mobile) {
        this.error_mesage.push("رقم الموبايل");
      }
      if(!this.form.role){
         this.error_mesage.push("نوع العضوية");
      }
      if (this.form.name && this.form.email && this.form.mobile && this.form.role) {
        let formData = new FormData();
        formData.append("name", this.form.name);
        formData.append("email", this.form.email);
        formData.append("company", this.form.company);
        formData.append("job_title", this.form.job_title);
        formData.append("department", this.form.department);
        formData.append("country_code", this.form.country_code);
        formData.append("mobile", this.form.mobile.replace(/\s/g, ""));
        formData.append("avatar", this.user_photo);
        formData.append("_method", "PUT");
        formData.append("role_id", this.form.role.id);
      

        this.$vs.loading();
        users
          .UpdateUser(userid, formData)
          .then(() => {
            this.form = {};
            this.getAllUser();
            this.closePopup();
            this.$vs.loading.close();
            this.$vs.notify({
              text: "تم تعديل العضو بنجاح",
              color: "success",
            });
            
          })
               .catch((err) => {
            this.$vs.loading.close();
           
            if(err.response.data.error=='The email has already been taken.'){
              this.API_ERROR = 'email_taken'
            }
            else if(err.response.data.error=='The mobile has already been taken.'){
              this.API_ERROR = 'mobile_taken'
            
              
            }
            else if(err.response.data.error=='The mobile must be a number.'){
              this.API_ERROR = 'mobile_notvalid'
            }
            else if(err.response.data.error=='The email must be a valid email address.'){
               this.API_ERROR = 'email_notvalid'
            }
            else{
               if(err.response.data.error){
              this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
            }
            else{
              
               this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
            }
            }
            
            window.scrollTo(0,0)
          });
      }
    },
    getUserRoles() {
      this.AllRoles = [];

      users
        .getuserRoles()
        .then((res) => {
          this.AllRoles = res.data.data;
          this.$vs.loading.close();
        })
           .catch((err) => {
            this.$vs.loading.close();
            if(err.response.data.error){
              this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
            }
            else{
              
               this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
            }
           
          });
    },
  },

  created() {
    this.getUserRoles();
    if (this.action == "edit") {
      this.getSingleUser(this.selectedUser.id);
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.getUserRoles();
  },
};
</script>
